import { Injectable, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef, ComponentRef } from '@angular/core';
import { LightboxComponent } from './lightbox.component';
import { LightboxTouchscreenComponent } from './lightbox-touchscreen.component';
import { Properties, LightboxData } from './interfaces';
import { DefaultProperties } from './default-properties';
import { Utils } from './utils';

export interface AdComponent {
    lightboxData: LightboxData;
    events: any;
}

@Injectable()
export class CrystalLightbox {
    isMobile: boolean;
    _defaultProperties: Properties;
    componentRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector) {}

    appendComponentToBody(component: any, lightboxData: LightboxData) {
        this.componentRef = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);

        // Pass custom data
        (<AdComponent>this.componentRef.instance).lightboxData = lightboxData;

        this.appRef.attachView(this.componentRef.hostView);
        const domElem = (this.componentRef.hostView as EmbeddedViewRef < any > ).rootNodes[0] as HTMLElement;

        // Add to body
        document.body.appendChild(domElem);

        // Subscribe to events
        (<AdComponent>this.componentRef.instance).events.subscribe((event) => {
            this.handleEvents(event);
        });
    }

    handleEvents(event: any) {
        if (event.type === 'close') {
            this.close();
        }
    }

    open(lightboxData: LightboxData) {
        lightboxData.properties = this.applyPropertieDefaults(DefaultProperties, lightboxData.properties);
        this.isMobile = Utils.mobileCheck();
        let component = this.getLightboxComponent(lightboxData.properties);
        this.appendComponentToBody(component, lightboxData);
    }

    close() {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
    }

    getLightboxComponent(properties: Properties) {
        return (this.isMobile && properties.gestureEnable) ? LightboxTouchscreenComponent : LightboxComponent;
    }

    applyPropertieDefaults(defaultProperties, properties) {
        if (!properties) {
            properties = {};
        }

        if (!properties.index) {
            properties.index = 0;
        }
        this._defaultProperties = Object.assign({}, defaultProperties);
        return Object.assign(this._defaultProperties, properties);
    }
}