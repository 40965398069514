import { Component, OnInit } from '@angular/core';
import { CrystalLightboxModule} from '../ivybox-ng8+/lightbox.module'

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  protected lightbox:any

  constructor(private lightBox: CrystalLightboxModule) { 
    this.lightbox = lightBox
  }

  ngOnInit(): void {
  
  }

}
