import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reproduction',
  templateUrl: './reproduction.component.html',
  styleUrls: ['../overview/overview.component.css']
})
export class ReproductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
