<div class="content">
    <div class="row">
        <div class="card mx-auto" style="width: 85%;">
            <div class="card-body">
              <h5 class="card-title">Defense</h5>
              <p class="card-text">The ETF uses its marbled smooth body to hide itself in rocky areas from its predators.If its camoflauge fails and is found, it can send an electric shock through its body to chase the predators away.It can hold this shocking sensation for as long as it feels threatened.If the ETF is in the open sea, it can use its hammer-shaped eyes to see almost 360 degrees around it, therefore making it harder to sneak up on it.Its body is also relitively flat so it can turn and corner very easily to get away faster. </p>
              <div class="well text-center">
              <img src="../../assets/image/tenor.gif">
            </div>
            <div class="card-footer">
            </div>
            </div>
          </div>
    </div>
</div>