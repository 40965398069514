<div class="content">
    <div class="row">
        <div class="card mx-auto" style="width: 85%;">
            <div class="card-body">
              <h5 class="card-title">Reproduction</h5>
                <div class="list-group">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Attracting A Mate &nbsp;&nbsp; <img src="../../assets/image/silohette fish.gif" width="20%"></h5>
                    </div>
                    <p class="mb-1">To attract its mate the Male ETF will make a show of discharging electricity in close proximity to the female. The female is sensitive to small changes in electrical charge. Male ETF's will become territorial over area females and will engage in ramming any other males in the vicinity.</p>
                      <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Mating&nbsp;&nbsp; <img src="../../assets/image/explicit.gif" width="20%"></h5>
                    </div>
                    <p class="mb-1">After the wooing has completed, the victor approaches his prize. Upon a close distance being reached, the pair circle around one another signifying that they will be mated. Sperm injection is accomplished utilizing an inverted posture that aligns the appropriate glands for the exchange of biologic fluid.</p>
                  <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1">Birth&nbsp;&nbsp; <img src="../../assets/image/fish.gif" width="20%"></h5>
                    </div>
                    <p class="mb-1">The female carries the offspring until the fullness of gestation has completed (5 Months on average). The average number of offspring for each pregnancy is 10. Immediately upon birth the offspring are completely independent of the mother.The baby ETF's are almost completely developed, but not fully. They will take another month outside the womb to fully develop.</p>
                </div>
            <div class="card-footer">
            </div>
            </div>
          </div>
    </div>
</div>