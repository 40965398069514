<div class="content">
    <div class="row">
        <div class="card mx-auto " style="width: 85%;">
            <div class="card-body">
              <h5 class="card-title">Overview</h5>
              <p class="card-text">The Electric Thor Fish is a very intersting and special medium sized flat salt water fish that is often colored brown, grey and other darker colors. Their bodies are often marbled. It has 4 fins not counting its caudal tail fin.</p>
              <div class="well text-center">
                <div class="col-md-12">Photos and Videos:</div>
                <div class="row" style="display: flex; justify-content: center;">
                  <iframe src="https://albumizr.com/a/S5Ot" scrolling="no" frameborder="0" allowfullscreen width="850" height="500"></iframe>
                </div>
            </div>
            <div class="card-footer">
            </div>
            </div>
          </div>
    </div>
</div>
