<div class="content">
    <div class="row">
        <div class="card mx-auto" style="width: 85%;">
            <div class="card-body">
              <h5 class="card-title">Habitat</h5>
              <p class="card-text">The ETF is generally observed in areas surrounding the North Sea and the Norwegian Sea close to Norway. The ETF prefer colder temperatures. Normally they habitate rocky/muddy areas.These fish can go anywhere from the surface of the water to about 10,500 feet.(13,000 max depth of sea) The Norwegian sea has the lowest air pressure of the entire Arctic.The ETF likes the lower air pressure areas because it can go deeper in the ocean where its primary prey inhabit.</p>
              <div class="well text-center">
                <div #map style="width:100%;height:400px"></div>
            </div>
            <div class="card-footer">
            </div>
            </div>
          </div>
    </div>
</div>