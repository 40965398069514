<div class="content">
    <div class="row">
        <div class="card mx-auto" style="width: 85%;">
            <div class="card-body">
              <h5 class="card-title">Feeding Habits</h5>
              <p class="card-text">There are many naturally occuring food sources for the Electric Thor Fish. The ETF is an Omnivore (meaning that their diet consists of meat and plants). The ETF is an ambush hunter that utilizes environmental camouflage to wait for an oportune moment to strike its prey. This is to reduce the amount of energy and the amount of risk related to obtaining prey. A short table below lists known food sources.</p>
              <table class="table table-striped table-primary">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Food Source Name</th>
                    <th scope="col">Plant or animal</th>
                    <th scope="col">Size</th>
                    <th scope="col">Image</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mackerel</td>
                    <td>Animal</td>
                    <td>35cm - 70cm</td>
                    <td><img src="../../assets/image/mackerel.png" width="225px"></td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Flounder</td>
                    <td>Animal</td>
                    <td>30cm - 60cm</td>
                    <td><img src="../../assets/image/flounder.png" width="225px"></td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Haddock</td>
                    <td>Animal</td>
                    <td>50cm - 100cm</td>
                    <td><img src="../../assets/image/haddock.png" width="225px"></td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Green Algae</td>
                    <td>Plant</td>
                    <td>N/A</td>
                    <td><img src="../../assets/image/algae.jpg" width="225px"></td>
                  </tr>
                </tbody>
              </table>
              <div class="card-footer">
            </div>
            </div>
          </div>
    </div>
</div>
