import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { FeedingComponent } from './feeding/feeding.component';
import { ReproductionComponent } from './reproduction/reproduction.component';
import { HabitatComponent } from './habitat/habitat.component';
import { DefenseComponent } from './defense/defense.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './footer/footer.component';
import {CrystalLightboxModule} from './ivybox-ng8+/lightbox.module';
import { MerchandiseComponent } from './merchandise/merchandise.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    MerchandiseComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      {path: 'overview', component: OverviewComponent},
      {path: 'feeding', component: FeedingComponent},
      {path: 'reproduction', component: ReproductionComponent},
      {path: 'habitat', component: HabitatComponent},
      {path: 'defense', component: DefenseComponent},
      {path: 'faq', component: FaqComponent},
      {path: 'contact', component: ContactComponent},
      {path: 'merchandise', component: MerchandiseComponent},
      {path: '', redirectTo: 'overview', pathMatch: 'full'},
    ]),
    FontAwesomeModule,
    CrystalLightboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
