import { Component, OnInit } from '@angular/core';
import {} from 'googlemaps';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-habitat',
  templateUrl: './habitat.component.html',
  styleUrls: ['../overview/overview.component.css']
})
export class HabitatComponent implements OnInit {

  constructor() { }

  @ViewChild('map', {static: true}) mapElement: ElementRef;
  map: google.maps.Map;

  ngOnInit(): void {
    const mapProperties = {
         center: new google.maps.LatLng(60.4720, -8.4689),
         zoom: 4.6,
         mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement,mapProperties);
 }

}
