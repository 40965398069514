<div class="content">
    <div class="row">
        <div class="card mx-auto " style="width: 85%;">
            <div class="card-body">
              <h5 class="card-title">Merchandise</h5>
              <p class="card-text">Can't get enough of this sweet fish? Buy our merch! (to buy, use the contact us form to tell us what you want and what size!)</p>
              <div class="well text-center">
                <img src="../../assets/image/hoodie.png" width="25%">
                <img src="../../assets/image/hoodie2.png" width="25%">
                <img src="../../assets/image/hoodie3.png" width="25%">
            </div>
            <div class="card-footer">
            </div>
            </div>
          </div>
    </div>
</div>
